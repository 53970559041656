import type { SVGProps } from '@/types/common.types';

const ToggleActiveSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="24"
      viewBox="0 0 40 24"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_830_19956)">
        <rect width="40" height="24" rx="12" fill="#34C763" />
        <g filter="url(#filter0_d_830_19956)">
          <circle cx="28" cy="12" r="10" fill="white" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_830_19956"
          x="8"
          y="-5"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0588235 0 0 0 0 0.0588235 0 0 0 0 0.0705882 0 0 0 0.4 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_830_19956" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_830_19956"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_830_19956">
          <rect width="40" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ToggleActiveSVG;
