'use client';

import { JSX } from 'react';

import { isArray } from 'lodash';

import { ChildrenProps, ClassNameProps, SVGProps } from '@/types/common.types';

import { useDropdownContext } from '@/components/Dropdown/contexts/useDropdownContext';

import { List } from '@/components/List';
import { NavLink } from '@/components/NavLink';

// =================================================================

interface CommonProps extends ChildrenProps, ClassNameProps {
  disabled?: boolean;
  icon?: (props: SVGProps) => JSX.Element;
  activeClassName?: string;
  onClick?: () => void;
  isLast?: boolean;
}

export type DropdownOptionProps = CommonProps &
  (
    | {
        as: 'button';
        type?: 'button' | 'submit' | 'reset';
        href?: never;
        replace?: never;
        value?: string | number;
      }
    | {
        as: 'link';
        type?: never;
        href: string;
        replace?: boolean;
        value?: never;
      }
    | {
        as: 'li';
        type?: never;
        value?: string | number;
        href?: never;
        replace?: never;
      }
  );

// =================================================================

export const DropdownOption = (props: DropdownOptionProps) => {
  const {
    value,
    children,
    className,
    disabled,
    icon: Icon,
    as,
    activeClassName,
    onClick,
    ...rest
  } = props;

  const { onChange, value: defaultValue, toggleDropdown } = useDropdownContext();

  if (as === 'button') {
    return (
      <List.Element
        as="button"
        className={className}
        icon={Icon}
        isActiveElement={
          isArray(defaultValue) ? defaultValue.some(item => item === value) : defaultValue === value
        }
        onClick={() => {
          if (typeof onChange === 'function' && value !== undefined) {
            onChange(value);
          }

          toggleDropdown();
        }}
        disabled={disabled}
        activeClassName={activeClassName}
        {...rest}
      >
        {children}
      </List.Element>
    );
  }

  if (as === 'link') {
    return (
      <NavLink
        href={props.href}
        replace={props.replace}
        activeClassName={activeClassName}
        className={className}
        onClick={toggleDropdown}
        {...rest}
      >
        {children}
      </NavLink>
    );
  }

  if (as === 'li') {
    return (
      <li onClick={toggleDropdown} className={className}>
        {children}
      </li>
    );
  }
};
